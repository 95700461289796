import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import Layout from 'src/components/layout/Layout';
import PageComponentWrapper from 'src/components/pageComponentWrapper/PageComponentWrapper';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import PageSubtitle from 'src/components/pageSubtitle/PageSubtitle';
import PageTitle from 'src/components/pageTitle/PageTitle';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import SwitchedOnBillingLogoSVG from './img/SoB-stacked.svg';
import SwitchedOnFibreLogoSVG from './img/SoF-stacked.svg';
import PivotsLogoSVG from './img/pivots-logo.svg';
import VisimetrixLogoSVG from './img/visimetrix-logo.svg';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        pageTitle: string;
        pageDescription: string;
        title: string;
        subtitle: string;
        description: string;
      };
    };
  };
}

const Column = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;

  @media ${mediaQuery.tablet} {
    align-items: flex-start;
    width: 50%;
    height: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media ${mediaQuery.tablet} {
    flex-direction: row;
    justify-content: center;
  }
  @media ${mediaQuery.smallScreen} {
    padding-top: 50px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
  }
`;

const TextWrapper = styled.div`
  margin-top: 30px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    padding: 0;
  }
`;
const SolutionBox = styled(BoxContainer)`
  position: relative;
  overflow: hidden;
  @media ${mediaQuery.smallScreen} {
  }
  & ${Row}:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;

    @media ${mediaQuery.smallScreen} {
      background-size: contain;
      opacity: 1;
    }
  }
  &:nth-child(odd) {
    ${Row} {
      justify-content: left;
    }
  }
  & ${Row} {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    justify-content: right;

    @media ${mediaQuery.tablet} {
      & ${Column} {
        width: 50%;
      }
    }
  }
`;

const VisimetrixBox = styled(SolutionBox)`
  background: linear-gradient(#1299a7, #1299a7);
  & ${Row}:before {
    background: url('/backgrounds/lines_right.svg');
    background-size: cover;
    background-position: bottom right;
    width: 120%;

    @media ${mediaQuery.phone} {
      width: 200%;
    }
  }
`;

const SwitchedOnFibreBox = styled(SolutionBox)`
  background: linear-gradient(76.23deg, #2368d7 8.99%, #4e8ff6 102.45%);
  & ${Row}:before {
    background: url('/backgrounds/fibres_left.svg');
    background-size: contain;
    background-position: bottom left;
    opacity: 0.5;
    background-repeat: no-repeat;
    @media ${mediaQuery.smallScreen} {
      opacity: 1;
      width: 40%;
    }
  }
`;

const SwitchedOnBillingBox = styled(SolutionBox)`
  background: linear-gradient(76.23deg, #4e2fb5 8.99%, #6844e2 102.45%);
  & ${Row}:before {
    background: url('/backgrounds/switched-on-billing-pattern.svg');
    background-size: cover;
    background-position: bottom left;
    transform: scale(-1, -1);
    background-repeat: no-repeat;

    @media ${mediaQuery.smallScreen} {
      left: calc(-50% - 75px);

      background-size: contain;
    }
  }
`;

const SwitchedOnFibreBoxContentWrapper = styled(PageComponentWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 15px;
  @media ${mediaQuery.tablet} {
    margin: 0 40px;
  }
`;

const VisimetrixBoxContentWrapper = styled(PageComponentWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 15px;
`;
const VisimetrixLogo = styled(VisimetrixLogoSVG)`
  max-width: 100%;
`;
const SwitchedOnFibreLogo = styled(SwitchedOnFibreLogoSVG)`
  max-width: 100%;
`;
const SwitchedOnBillingLogo = styled(SwitchedOnBillingLogoSVG)`
  max-width: 100%;
`;

const PivotBox = styled(SolutionBox)`
  background-color: ${Colors.pivots.ocean500};
  & ${Row}:before {
    background: url('/backgrounds/pivots-bg.png');
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
  }
`;

const PivotsLogo = styled(PivotsLogoSVG)`
  max-width: 100%;
`;

const PivotsBoxContentWrapper = styled(PageComponentWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 15px;
`;

const PivotsAwardContainer = styled.div`
  padding-bottom: 24px;
  width: 80%;
  max-width: 200px;

  @media ${mediaQuery.tablet} {
    max-width: 280px;
  }
`;

export const Button = styled(Link)`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }
`;

const SubTitle = styled.div`
  font-size: 16px;
  height: 16px;
  color: ${Colors.white};
  margin-bottom: 8px;
  opacity: 0.5;
  font-size: 12px;
  text-transform: uppercase;
`;

const PortfolioPage = ({ data }: IProps) => {
  const { title, description, subtitle, pageTitle, pageDescription } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <PageTitle>{title}</PageTitle>
            <PageSubtitle>{subtitle}</PageSubtitle>
            <PageDescription>{description}</PageDescription>
          </PageHeading>

          <SwitchedOnFibreBox>
            <Row>
              <Column>
                <SwitchedOnFibreBoxContentWrapper>
                  <SubTitle>Telecom Solutions</SubTitle>
                  <SwitchedOnFibreLogo title="SwitchedOn Fibre™" />
                  <TextWrapper>
                    A modular BSS suite, automating lead-to-cash & trouble-to-resolve across wholesale, retail and
                    enterprise services
                  </TextWrapper>
                  <ButtonWrapper title="Discover SwitchedOn Fibre™">
                    <Button to="/telecom-solutions/switched-on-fibre" state={{ canGoBack: true }}>
                      More information
                    </Button>
                  </ButtonWrapper>
                </SwitchedOnFibreBoxContentWrapper>
              </Column>
            </Row>
          </SwitchedOnFibreBox>

          <PivotBox>
            <Row>
              <Column>
                <PivotsBoxContentWrapper>
                  <SubTitle>Telecom Solutions</SubTitle>
                  <PivotsLogo title="PivOTS" />
                  <TextWrapper>Secure, reliable One Touch Switching</TextWrapper>
                  <ButtonWrapper title="Discover pivOTS">
                    <Button to="/telecom-solutions/pivots-one-touch-switching" state={{ canGoBack: true }}>
                      More information
                    </Button>
                  </ButtonWrapper>
                </PivotsBoxContentWrapper>
              </Column>
              <Column>
                <PivotsAwardContainer>
                  <StaticImage src="img/connected-britain-award.png" alt="ConnectedBritain Award" objectFit="contain" />
                </PivotsAwardContainer>
              </Column>
            </Row>
          </PivotBox>

          <SwitchedOnBillingBox>
            <Row>
              <Column>
                <SwitchedOnFibreBoxContentWrapper>
                  <SubTitle>Telecom Solutions</SubTitle>
                  <SwitchedOnBillingLogo title="SwitchedOn Billing" />
                  <TextWrapper>A powerful platform to manage and monetise your subscriptions</TextWrapper>
                  <ButtonWrapper title="Discover SwitchedOn Billing">
                    <Button to="/telecom-solutions/switched-on-billing" state={{ canGoBack: true }}>
                      More information
                    </Button>
                  </ButtonWrapper>
                </SwitchedOnFibreBoxContentWrapper>
              </Column>
            </Row>
          </SwitchedOnBillingBox>

          <VisimetrixBox>
            <Row>
              <Column>
                <VisimetrixBoxContentWrapper>
                  <SubTitle>Telecom Solutions</SubTitle>
                  <VisimetrixLogo title="Visimetrix™" />
                  <TextWrapper>A new approach to network service monitoring and AIOps-enabled analytics</TextWrapper>
                  <ButtonWrapper title="Discover VisiMetrix™">
                    <Button to="/telecom-solutions/visimetrix" state={{ canGoBack: true }}>
                      More information
                    </Button>
                  </ButtonWrapper>
                </VisimetrixBoxContentWrapper>
              </Column>
            </Row>
          </VisimetrixBox>
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default PortfolioPage;

export const pageQuery = graphql`
  query PortfolioPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        pageDescription
        title
        subtitle
        description
      }
    }
  }
`;
